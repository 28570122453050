export const UscisTranslationData = [
  {
    title: "Birth certificate",
    // link: " ",
    detail:
      "You can entrust us to translate your birth certificates with accuracy and precision, no matter where you were born and where you want to travel.",
  },
  {
    title: "Marriage certificat",
    // link: " ",
    detail:
      "Marriages should be easy. So is the marriage certificate translation for the USCIS with Tomedes.",
  },
  {
    title: "Divorce",
    // link: " ",
    detail:
      "Divorces can work out, with Tomedes USCIS certified translation services. ",
  },
  {
    title: "Police Confirmation",
    // link: " ",
    detail:
      "Translation companies like Tomedes could be a way to obtain police clearances, even for those who have limited English proficiency.",
  },
  {
    title: "Academic Documents",
    // link: " ",
    detail:
      "Academic documents, for professional and academic applications, can be translated to make sure all your current achievements don't get lost in translation.",
  },
];

export const LanguagesInUscis = [
  {
    heading: "Spanish",
    detail:
      "Citizens of the US as well as those outside of the US may need translation services to translate from Spanish to English. That’s why we have USCIS translation services to cater to the needs of the Spanish-speaking population.",
    link: "/spanish-translation.php",
    img_link: "",
  },
  {
    heading: "Chinese",
    detail:
      "If you need certified USCIS translations or accredited English translations from Chinese, don’t hesitate to ask Tomedes’ USCIS translation services. A government-certified document translator will make sure all your translations are accredited to pass for all your requirements at USCIS.",
    link: "/mandarin-chinese-translation",
    // img_link: "",
  },
  {
    heading: "Hindi",
    detail:
      "Hindi-to-English official translations shouldn’t be too difficult for you if you obtain the help of Tomedes for immigration translation services. We accommodate Hindi to English, English to Hindi, or any of our other 950+ language pairs. Contact us and find a Hindi certified translator today.",
    link: "/languages/hindi",
  },
  {
    heading: "Tagalog",
    detail:
      "If you need USCIS approved translation services for Tagalog-English, or a Tagalog-to-English translator, don’t hesitate to reach out through our contact form. You can translate your birth certificates, marriage certificates, divorce certificates, and more.",
    link: "/languages/tagalog",
    // img_link: "",
  },
  {
    heading: "French",
    detail:
      "French-to-English USCIS translation service is at the tip of your fingers, when you make use of Tomedes’ translation services. An accredited translator for your certified and notarized translations can be guaranteed by us for acceptance at USCIS.",
    link: "/languages/french",
    // img_link: " ",
  },
  {
    heading: "Vietnamese",
    detail:
      "Vietnamese-to-English official USCIS translation services are one click away at Tomedes. If you’re looking to translate from Vietnamese to any of our 120 languages available, you’re in the right place. You can avail of birth certificate translation, legal translation, and more for USCIS in this language.",
    link: "/languages/vietnamese",
    // img_link: " ",
  },
];

export const SliderUscisData = [
  {
    heading: "Does USCIS require certified translations?",
    para: "Yes, USCIS requires all translated documents submitted are certified by either a certified translation agency or a certified translator.",
    id: "112",
  },
  {
    heading: "Can I translate documents for the USCIS?",
    para: "No, you cannot translate your own documents for submission in USCIS. Translating your own documents does not guarantee that the translated documents are accurate and faithful to the source document.",
    id: "223",
  },
  {
    heading: "Can you translate my legal documents?",
    para: "Yes, we can also translate your documents not only for the USCIS but also for other legal purposes like litigations, hearings, etc.",
    id: "334",
  },
  {
    heading: "How do I find someone to translate USCIS documents near me?",
    para: "We can easily find someone to translate USCIS documents near you. Just let us know your location, and we'll match you with the best translator.",
    id: "445",
  },
];
