import { Link } from "gatsby";
import React from "react";
import uscisSvg from "../../../assets/images/uscis-translation/uscis.svg";
export default function NewCertify({
  backgroundCol,
  head,
  colHead,
  secondHead,
  para,
  leftHeadingFirst,
  leftHeadingmid,
  leftHeadingSecond,
  leftHeadingPara,
  src,
  bottomleftHeadingFirst,
  bottomleftHeadingmid,
  bottomleftHeadingSecond,
  bottomleftHeadingPara,
  RightHeadingFirst,
  RightHeadingmid,
  RightHeadingSecond,
  RightHeadingPara,
  ListFirst,
  ListSecond,
  ListThird,
  ListFourth,
}) {
  return (
    <div className={backgroundCol ? backgroundCol : "bg-darkBlue"}>
      <div className="mainDiv flex lg:flex-col flex-wrap mx-auto justify-center w-full lg:w-10/12 pt-24 max-w-6xl">
        <div className="divTop  flex flex-col justify-center items-center relative">
          <img src={uscisSvg} alt="" className="absolute -top-5 inline-block w-full lg:w-10/12" />
          <h2 className="md:text-[45px] leading-[60px] text-2xl text-center text-white font-extrabold uppercase z-50 mt-4">
            <span>How we translate your</span>
            <br />
            <span className="text-orange">USCIS </span>
            <span>Documents</span>
          </h2>
          <p className="text-center text-white text-[18px] font-light py-6 px-4 md:px-0 font-opensans">
            {para
              ? para
              : "We deliver high quality translation services for USCIS that meet their rigid requirements. Translating documents for USCIS is done by expert translators with expertise in immigration services."}
          </p>
        </div>
        <div className="div flex flex-wrap mx-auto justify-center py-24 lg:px-0 px-4 gap-x-32">
          <div className="leftText text-white w-full lg:w-4/12 flex-col">
            <h3 className="text-[26px] leading-[40px] font-secondary font-normal pb-6">{leftHeadingFirst ? leftHeadingFirst : "USCIS certified translations"}</h3>
            <p className="font-extralight font-opensans text-sm leading-loose">
              {leftHeadingPara ? leftHeadingPara : "Tomedes is authorized to certify that a translated document for USCIS exactly and faithfully represents the content of the original. "}
              <br />
              <br />
              <span className="mt-8">
                Certified translation services are required by governmental authorities at USCIS. We guarantee 100% that our translation certificates will be accepted universally, with a one-year
                guarantee.
              </span>
            </p>
          </div>
          <div className="rightComponent text-white  w-full lg:w-4/12 flex-col lg:pt-0 pt-6">
            <h3 className="text-[26px] font-secondary font-normal pb-6 leading-[40px]" style={{ lineHeight: "40px", fontSize: "26px" }}>
              Translating For Acceptance In The USCIS
            </h3>
            <p className="text-[#BEBEBE]">The USCIS has stringent requirements to be followed. That's why we go with the three-point system for translating documents for the USCIS:</p>
            <ul className="divcontent  list-outside pb-8 md:pb-14 mt-6 list-none">
              <li className="text-orange">
                <span>Translate </span>
                <span className="text-white text-sm  font-extralight leading-relaxed">
                  - The native translator is tasked to translate the document, with all the expertise of citizenship and immigration.
                </span>
              </li>
              <li className="text-orange pt-4">
                <span>Certify </span>
                <span className="text-white text-sm  font-extralight leading-relaxed">
                  - Tomedes is certified by the American Translator's Association and the International Organization for Standardization for accuracy and acceptance, and will certify that your
                  documents are ready for submission to USCIS
                </span>
              </li>
              <li className="text-orange pt-4">
                <span>Delivery </span>
                <span className="text-white text-sm  font-extralight leading-relaxed"> - Your translation is done and your document will be sent via mail or online delivery</span>
              </li>
            </ul>
          </div>
          <div className="divcontent rightComponent text-white flex-wrap md:flex-nowrap justify-between w-full lg:w-9/12 flex lg:pt-0 pt-6">
            <div className="div">
              <h3 className="text-2xl font-secondary font-bold pt-0 md:pt-[2.5rem] leading-10 mb-8 ">How We Hire Your USCIS Certified Translator</h3>
              <Link to="/translators" className="rounded-full py-2 px-6 bg-orange hover:bg-[#ff9a16] transition-colors duration-300">
                LEARN MORE
              </Link>
            </div>
            <div className="div flex w-full  md:w-[50%]">
              <p className="font-extralight font-opensans text-sm py-10 leading-relaxed">
                <span>Our global network of more than 10,000 translators in six continents enables us to certify your documents, backed up by our 24/7 customer support.</span>
                <br />
                <br />
                <span> We strategically hire native certified translators who are in different time zones to prepare for your needs, so you can be accommodated anytime, anywhere.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
