import React from "react";

function HighQuality({ firstHeading, colouredHeading, secondHeading, BackgroundCol }) {
  return (
    <div className={`flex ${BackgroundCol ? BackgroundCol : "bg-midBlue"} items-center justify-center py-20`}>
      <div className="flex items-center justify-center px-4 md:px-16 w-full sm:px-8 xl:w-8/12 text-center max-w-screen-xl">
        <p className="text-[26px] leading-8 md:leading-[52px]  font-secondary ">
          <span className="text-white">{firstHeading ? firstHeading : "We aspire to provide the highest quality"}</span>{" "}
          <span className="text-orange">{colouredHeading ? colouredHeading : "birth certificate"}</span>{" "}
          <span className="text-white">
            {secondHeading
              ? secondHeading
              : "translation services helping you reach your global objectives. We support certified birth certificate translations that align with your goals for travel, immigration, and study abroad."}
          </span>
        </p>
      </div>
    </div>
  );
}

export default HighQuality;
