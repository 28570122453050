import Layout from "components/layout";
import UscisTranslationServices from "components/reusableComponents/UscisTranslation/UscisTranslationServices";
import SEO from "components/seo";
import React from "react";

function index() {
  const description =
    "Need your documents translated for the United States Citizenship and Immigration Service? Tomedes provides guaranteed and certified USCIS translation services.";
  const title = "USCIS Translation Services | Tomedes";
  const keywords = "USCIS Translation Services";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/uscis-translation-services"
      />
      <UscisTranslationServices />
    </Layout>
  );
}

export default index;
