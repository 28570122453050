import Reviews from "components/homepage/reviews";
import WhatChooseUs from "components/homepage/whyChooseUs";
import LocationBanner from "components/locationBanner";
import Popular from "components/LocationReusableComp/popular";
import React from "react";
import Birthcomp from "../BirthCertificate/Birthcomp";
import FAQ from "../BirthCertificate/FAQs";
import HighQuality from "../BirthCertificate/HighQuality";
import VariousTranslation from "../VariousTranslation";
import WhatsAppComp from "../whatsappComp";
import { LanguagesInUscis, SliderUscisData, UscisTranslationData } from "./config";
import NewCertify from "./NewCertify";

function UscisTranslationServices() {
  return (
    <>
      <LocationBanner
        backgroundCol="bg-midBlue"
        title="USCIS Translation Services"
        content="The United States Citizenship and Immigration Service (USCIS) requires many documents as a condition for approving work, study and citizenship in the USA. Translations of all foreign language documents must be certified as compliant with USCIS standards. Tomedes stands behind the quality of its USCIS translation services with a 100% money-back guarantee."
        formOrangeHead="Looking"
        formWhiteHead="to translate your
        USCIS documents?"
      />
      <Birthcomp
        backgroundCol="bg-usisc-banner"
        firstHead="A Translation Agency You Can"
        midHead="Trust"
        secondHead="For Your Immigration Needs"
        para="Our immigrant translation services are trusted by the USCIS. We are ISO-certified to provide USCIS document translation and USCIS certified translation services, and we provide certification of translations upon request. Whatever you aspire to be, we can help you in your journey."
      />
      <HighQuality
        firstHeading="Broaden your horizons with aim. Our"
        colouredHeading="USCIS"
        secondHeading="translation services will solve your problems when it comes to applications that are not in your language. USCIS translations will provide global solutions to your work, study, and immigration problems."
      />

      <NewCertify />
      <WhatsAppComp backgroundCol="bg-midBlue" fHeadCol="text-orange" fHead="Translating" secondHead="Your Documents for USCIS Now" content=" " whatsUp />
      <Popular
        bottomBorderText
        footerTitle="Didn’t See The Language Your Documents Are In?"
        footerDetails="Tomedes supports over 120 languages and 950+ language pairs. If you didn’t see the language your documents are in, learn more below about the language pairs you want to translate."
        backgroundCol="bg-darkBlue"
        languageCount="Translations From Any Language Into English"
        details="Tomedes commands a global network of tens of thousands of translators, and Tomedes translators are required to have certifications, necessary experience, and a certain level attained in linguistic expertise. Commonly supported languages are:"
        bottomHeading=" "
        bottomDetails=" "
        location=" "
        data={LanguagesInUscis}
        btnName="SEE OUR SUPPORTED LANGUAGES"
      />
      <VariousTranslation backgroundCol="bg-midBlue" ftitle=" " para=" " title="Common Documents Needed To Be Translated For The USCIS" data={UscisTranslationData} />
      <FAQ SliderData={SliderUscisData} />
      <WhatChooseUs backgroundCol="bg-midBlue" />
      <Reviews backgroundCol="bg-darkBlue" />
    </>
  );
}

export default UscisTranslationServices;
